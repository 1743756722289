.About-Me{
  width: 100%;
  height: auto;
  background-color: rgb(2,4,18);
  margin-bottom: 80px;
  display: grid;
  grid-template-columns: 1rem 2fr 2fr 2fr 1rem;
  color: white;
}
.about-details{
  display: flex;
  flex-direction: column;
justify-content: center;
  font-size: 20px;
}
.about-details h1,.certificate h1{
  font-size: 40px;
margin-top: 0;
border: 1px solid white;
width: fit-content;
padding: 10px;
}
.about-pic{
  display: grid;
  grid-column: 2/3;
}
.certificate{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.certificate img{
  width: 250px;
  height: auto;
  rotate: -0.5deg;
}
/* Responsive */
@media (max-width: 880px){
.About-Me{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.About-Me img{
  width: 250px;
  height: 350px;
  padding-bottom: 20px;
}
.about-details{
  padding-bottom: 20px;
}
}