
  .contact-map{
    display: grid;
    grid-column: 2/3;
  }
  .contact-form{
margin: 20px 20px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
  }
  .contact-form input{
    height: 40px;
    width: 350px;
    margin-top: 20px;
    padding-left: 15px;
    outline: none;
    font-size: 15px;
  }
  .contact-form textarea{
    width: 350px;
    margin-top: 20px;
    outline: none;
    padding-left: 15px;
    font-size: 20px;
  }
  .send-btn{
    display: flex;
 justify-content:center;
 margin-right: 245px;
  }
  .contact-form button{
    height: 50px;
    width: 100px;
    margin: 20px 0;
    background-color: rgb(41, 105, 179);
    color: aliceblue;
  border: none;
  font-size: 20px;
    border-radius: 25px;
  }
.send-btn button:hover{
background-color: rgba(240, 86, 29, 0.911);
transform:scale(0.9);


}