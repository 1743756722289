@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
}
html {
  font-size: 62.5%;

}

a {
  text-decoration: none;
  font-size: 20px;
  color: aliceblue;
}
.menu-link ul li :hover{
  color: orangered;
  
}
li {
  list-style: none;
}

/* 
 
/* navbar style start  */
.main-nav{
    width: 100%;
    height: 10rem;
    display: grid;
    grid-template-columns: 10rem 1fr 2fr  10rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    background-color: #020410;
    

}
.logo{
    grid-column: 2/3;
    display: flex;
    align-items: center;
}

.logo h2 {
    font-size: 2.2rem;
    font-weight: 400;
    text-transform: uppercase;
    /* background: -webkit-linear-gradient(rgb(231, 233, 233), rgba(250, 250, 250, 0.904));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    cursor: pointer;
    color: white;
    
  }
  .logo h2 span {
    font-size: 4rem;
    color: orangered;
  }
  .logo h2::before{
    content: '<';

  }
  .logo h2::after{
    content: '/>';   
  }
.menu-link{
    grid-column: 3/4;
}
.menu-link ul{
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.menu-link ul li a{
    text-transform: capitalize;
    color: rgb(255, 255, 255);
    font-size: 20px
}
.social-media{
    grid-column: 4/5;
}
.hamburger-menu{
    display: none;
  }
  /* responsive css style  */
@media (max-width: 990px) {
    .main-nav {
      height: 7rem;
      grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
    }
    .menu-link {
      display: none;
    }
  
    .logo,
    .hamburger-menu ul {
      height: 7rem;
    }
  
    .mobile-menu-link {
      grid-column: 2/4;
      position: relative;
      z-index: 99;
    }
    .mobile-menu-link {
      background-color: rgb(2,4,16);
      height: 20rem;
      display: grid;
      grid-column: 2/5;
      align-items: center;
      padding-left: 3rem;
      transition: all 2s linear;
      transform-origin: top;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    }
    .mobile-menu-link ul  {
      height: 20rem;
      display: flex;
      font-size: 25px;
      align-items: center;
      flex-direction: column;
   text-transform: capitalize;
    }
    .mobile-menu-link ul li a:hover{
      color: orangered;
    }

    .hamburger-menu {
      height: 7rem;
      display: flex;
      justify-self: end;
      align-items: center;
      grid-row: 1/2;
      grid-column: 3/5;
      justify-items: end;
      transition: all 2s linear;
    }
  .hamburger-menu a{
      display: block;
      font-size: 2.5rem;
    }
  }
  @media (max-width: 798px) {
    .main-nav {
      height: 6rem;
      grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
    }
    .logo,
    .social-media ul {
      height: 6rem;
    }
    .hamburger-menu {
      height: 6rem;
      display: flex;
      justify-self: end;
      align-items: center;
    }
 .hamburger-menu a{
      display: block;
      font-size: 2.5rem;
    }
  }
  
  @media (max-width: 520px) {
    .main-nav {
      height: 6rem;
      grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
    }
    .logo h2 {
      font-size: 2rem;
    }
  
    hamburger-menu {
      height: 5rem;
      display: flex;
      align-items: center;
    }

    .hamburger-menu a{
      display: block;
      font-size: 2.5rem;
    }
  }