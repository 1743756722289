.common-heading  {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    text-transform: capitalize;
    position: relative;
  }
  .common-heading h1{
    font-size: 8rem;
    color: orangered;
  }

  .skill-section{
    display: grid;
    grid-template-columns: 10rem 2fr 2fr 10rem;
    background-color: rgb(2,4,16);
    color: aliceblue;
  }
  .skill-img{
    display: grid;
    grid-column: 2/3;
  }
  .My-skills{
margin-top: 30px;
  }
  .knowledge{
    padding-bottom: 45px;
  }
  @media (max-width: 1020px){
.skill-section{
  width: 100%;
  height: auto;
  background-color: black;
  display: flex;
  flex-direction: column;
}
.skill-img{
  width: 100%;
  display: flex;
justify-content: center;
align-items: center;
}
.skill-img img{
width: 100%;
}


  }