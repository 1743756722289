.scrollTop{
    height: 60px;
    width: 60px;
    background-color: rgb(47, 0, 255) !important;
    display: flex;
    align-items: center;
    justify-content: center;
  position: fixed;
  right: 0;
  top: 90%;
font-size: 40px;
cursor: pointer;
border-radius: 40px;

}
.scrollTop svg{
    color: aliceblue;
    text-decoration: none;
    animation: svg 1.2s linear infinite alternate-reverse;
}
@keyframes svg{
0%{
    transform: translateY(-0.5rem);

}
100%{
    transform: translateY(1rem);
}

}

