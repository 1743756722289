
.row{
  width: 100%;
  padding-left: 7px;
}
.col{
  margin-top: 20px;
  cursor: pointer;
}

.card{
  height: 400px;
  background-color: rgba(158, 148, 247, 0.329);
}
.card img{
  height: 280px;
}
.card-btns{
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
}
.card-btns a{
  text-decoration: none;
  color: black;
}
.card-btns Button{
  height: 40px;
  font-size: 20px;
  background-color: white;
  border: 1px solid black;
}
.card-btns Button:hover{
  background-color:white;
  border: 1px solid black;
}
.category-btns{
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
}
.category-btns Button{
  height: 50px;
  width: 120px;
margin: 10px;
font-size: 20px;
background-color: aliceblue;
color: black;
border: 2px solid black;
border-radius: 20px;
}
.category-btns Button:hover{
font-size: 20px;
transform: scale(1.1);
color: whitesmoke;
background-color: black;
border: 2px solid whitesmoke;
}
@media (max-width:500px){
  .category-btns button{
    font-size: 14px;
  }

}
@media (max-width:250px){
  .category-btns button{
    font-size: 10px;
  }

}