@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles:wght@700&family=Montserrat:ital,wght@1,700&family=Roboto+Slab:wght@600&family=Roboto:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles:wght@700&family=Montserrat:ital,wght@1,700&family=Roboto+Slab:wght@600&family=Roboto:ital@0;1&display=swap');
.hero-section{
    width: 100%;
    height: 60vh;
    display: grid;
    align-items: center;
    grid-template-columns: 10rem 1fr 2fr 7rem;
    height: 100vh;

}
.profile-pic{
padding-left: 20px;
    grid-column: 2/3;
    }
.profile-pic img{
    height: 55rem;
    margin-top: 1rem;

rotate: -1deg;
}
.details{
    height: 50rem;

    margin-right: 60px;
margin-top: 3rem;
}
.details::before{
    content: '<>';
    font-size: 20px;
}
.details::after{
    content: '</>';
    width: 20rem;
    font-size: 20px;
    display: flex;
    justify-content: center;
    
}
.details h1{
    font-size: 40px;
}
.name{
    font-size: 60px;
    color:#ff4500;
}
.status{
    height: 70px;


}
.status h1{
    width: fit-content;
    font-size: 60px;
    color: rgb(17, 144, 230);
}
   .location p{
    margin: 25px 0;
    font-size: 25px ;
    font-family: 'Roboto Slab', serif;
 
   }

.buttons{
    width: 37rem;
    display: flex;
  justify-content: space-between;
}
.buttons button{
    height: 50px;
    width: 18rem;
    border-radius: 20px;
    font-size: 25px;
    cursor: pointer;
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.buttons button:hover{
    color: aliceblue;
background-color: black;
}
.social-icons{
    height: 100%;
    display: flex;
    grid-column: 4/5;
    font-size: 32px;
    cursor: pointer;
    margin-top: 35rem;
/* padding-left: 40px; */
}
.social-icons a{
    text-decoration: none;
}
.facebook a{
    color: blue;
}
.instagram a{
    color: rgb(248, 145, 11);
    text-decoration: none;
}
.github a{ 
    color: black;
    text-decoration: none;
}
.social-icons svg{
    animation: svg 1.2s linear infinite alternate-reverse;
}
@keyframes svg{
    0%{
        transform: translateY(-0.5rem);
    
    }
    100%{
        transform: translateY(1rem);
    }
    
    }
/* responsive section for hero page */
@media (max-width: 940px) {
    .details h1{
        font-size: 30px;
    }
.status h1{
    font-size: 45px;
}
.location p{
font-size: 20px;
margin: 8px 0;
}
.social-icons{
    display: none;
}
}
@media (max-width:820px){
   .hero-section{
    display: grid;
    grid-template-columns: 1rem 1fr 2fr;
   }
   .social-icons{
    display: none;
}
   .profile-pic{
    display: grid;
    grid-column: 2/3;
   }
    .status{
        height: 40px;
    }
  .status h1{
  font-size: 42px;
  }
  .name{
    font-size: 50px;
  }
    .buttons{
        width: 32rem;
    }
    .buttons button{
     width: 15rem;
margin-top: 10px;
    }

    .profile-pic img{
        width: 220px;
        height: 450px;
        
    }
    .location p{
        font-size: 23px;
        margin-top: 8px;
        margin-bottom: 0;
        }
  .details{
    padding-top: 50px;
  }
}
@media (max-width:725px){
    .hero-section{
height: fit-content;
  padding-bottom: 10px;
    }

    .buttons{
        width: fit-content;
        height: auto;
        display: flex;
        flex-direction: column;
    }
    .name{
        font-size: 30px;
      }
    .status{
        height: 27px;
    }
  .status h1{
  font-size: 20px;
  }
  .social-icons{
    display: none;
}
}
@media (max-width:465px){
    .hero-section{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .details{
        margin-top: 8px;
        padding-top: 0;
        height: fit-content;
    }
 
.profile-pic{
    width: 100%;
    /* background-color: red; */
    justify-content: center;
    /* rotate: 0deg; */
margin-right: 50px;
}
.social-icons{
    display: none;
}
.buttons button{
    width: fit-content;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 6px;
  
}

}

/* CSS FOR ERROR PAGE */
.error{
width: 100%;

}
.error img{
    width: 100%;
}
